import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const Privacy = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Privacy Policy
      </Typography>
      
      <Typography variant="subtitle2" color="text.secondary" sx={{ fontStyle: 'italic', mb: 4 }}>
        Last updated: {new Date().toLocaleDateString()}
      </Typography>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          Our Privacy Commitment
        </Typography>
        <Typography paragraph>
          We believe in complete transparency and minimal data collection. This application 
          is designed to respect your privacy by collecting only what's absolutely necessary 
          for functionality.
        </Typography>
      </Box>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          Data Collection
        </Typography>
        <Typography paragraph>
          We collect minimal data to provide our service:
        </Typography>
        <Box sx={{ ml: 4, mb: 2 }}>
          <Typography component="ul">
            <li>Essential application data stored locally in your browser</li>
            <li>No personal information is collected</li>
            <li>No data is shared with third parties</li>
          </Typography>
        </Box>
      </Box>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          Local Storage
        </Typography>
        <Typography paragraph>
          Any data stored is kept locally on your device using browser storage. 
          This data never leaves your device and is only used to improve your 
          experience with the application.
        </Typography>
      </Box>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          Third-Party Services
        </Typography>
        <Typography paragraph>
          Our application may contain links to external services. This privacy policy 
          applies only to our application. We encourage you to read the privacy policies 
          of any external services you visit.
        </Typography>
      </Box>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          Updates to This Policy
        </Typography>
        <Typography paragraph>
          We may update this privacy policy as our services evolve. Any changes will 
          be reflected on this page with an updated revision date.
        </Typography>
      </Box>

      <Box component="section" sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          Contact Us
        </Typography>
        <Typography paragraph>
          If you have any questions about this privacy policy or our practices, 
          please contact us at contact@aaiimm.com
        </Typography>
      </Box>
    </Container>
  );
};

export default Privacy; 