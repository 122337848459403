import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Privacy from './components/Privacy';
import { Box, Button, Container, Typography, Dialog } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useState } from 'react';

const theme = createTheme({
  palette: {
    primary: {
      main: '#DC143C',
      dark: '#C11F6D',
    },
  },
  typography: {
    logo: {
      fontSize: '2.5rem',
      fontWeight: 700,
      letterSpacing: '0.1em',
    },
  },
});

function App() {
  const [lightbox, setLightbox] = useState({
    open: false,
    image: '',
  });

  const handleOpenLightbox = (imageNumber) => {
    setLightbox({
      open: true,
      image: `/screenshots/${imageNumber}.png`,
    });
  };

  const handleCloseLightbox = () => {
    setLightbox({
      open: false,
      image: '',
    });
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <ThemeProvider theme={theme}>
            <Container maxWidth="lg">
              <Box
                sx={{
                  minHeight: '100vh',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  py: 8,
                  gap: 4,
                }}
              >
                {/* Logo */}
                <Box 
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    cursor: 'default',
                    my: 3,
                  }}
                >
                  <img 
                    src="/icons/icon.svg" 
                    alt="Aim Search icon" 
                    style={{ 
                      width: '100px', 
                      height: '100px',
                    }} 
                  />
                </Box>

                {/* Title */}
                <Typography variant="h2" component="h2" align="center" gutterBottom>
                  Search smarter, find faster
                </Typography>

                {/* Summary */}
                <Typography variant="h5" align="center" color="text.secondary" sx={{ mb: 4 }}>
                  A powerful search extension that lets you quickly search across multiple engines.
                </Typography>

                {/* Screenshots */}
                <Box 
                  sx={{ 
                    display: 'grid',
                    gridTemplateColumns: {
                      xs: '1fr',           // 1 column on mobile
                      sm: 'repeat(2, 1fr)', // 2 columns on tablet
                      md: 'repeat(3, 1fr)', // 3 columns on desktop
                    },
                    gap: 3,
                    mb: 4,
                    width: '100%',
                    maxWidth: '1000px'
                  }}
                >
                  {[1, 2, 3, 4, 5, 6].map((imageNumber) => (
                    <img
                      key={imageNumber}
                      src={`/screenshots/${imageNumber}-640.png`}
                      alt={`Aim Search Screenshot ${imageNumber}`}
                      onClick={() => handleOpenLightbox(imageNumber)}
                      style={{
                        width: '100%',
                        aspectRatio: '3/2',
                        objectFit: 'cover',
                        borderRadius: '12px',
                        boxShadow: '0 4px 16px rgba(0,0,0,0.1)',
                        cursor: 'pointer',
                        transition: 'all 0.3s ease',
                      }}
                      sx={{
                        '&:hover': {
                          transform: 'translateY(-4px)',
                          boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
                        },
                      }}
                    />
                  ))}
                </Box>

                {/* Try Now Button */}
                <Button
                  variant="contained"
                  size="large"
                  component="a"
                  href="https://chromewebstore.google.com/detail/aaiimm/dhnnjmnnnmdpnlkkmlhppligleiefdhk"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ 
                    fontSize: '1.2rem',
                    py: 1.5,
                    px: 4,
                    textDecoration: 'none',
                    background: 'linear-gradient(45deg, #DC143C 30%, #C11F6D 90%)',
                    boxShadow: '0 3px 5px 2px rgba(220, 20, 60, .3)',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #C11F6D 30%, #923D88 90%)',
                      transform: 'translateY(-2px)',
                      boxShadow: '0 6px 10px 2px rgba(220, 20, 60, .3)',
                    },
                  }}
                >
                  Try Now
                </Button>

                {/* Lightbox Dialog */}
                <Dialog
                  open={lightbox.open}
                  onClose={handleCloseLightbox}
                  maxWidth="xl"
                  onClick={handleCloseLightbox}
                  PaperProps={{
                    style: {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                    },
                  }}
                >
                  <img
                    src={lightbox.image}
                    alt="Full size screenshot"
                    style={{
                      maxWidth: '95vw',
                      maxHeight: '95vh',
                      objectFit: 'contain',
                      borderRadius: '12px',
                    }}
                  />
                </Dialog>

                {/* Footer Links */}
                <Typography 
                  component="div" 
                  sx={{ 
                    mt: 4,
                    opacity: 0.6,
                    fontSize: '0.875rem',
                    display: 'flex',
                    gap: 2
                  }}
                >
                  <Button 
                    component={Link} 
                    to="/privacy" 
                    color="inherit" 
                    sx={{ 
                      textTransform: 'none',
                      fontSize: 'inherit'
                    }}
                  >
                    Privacy
                  </Button>
                  <Button
                    component="a"
                    href="https://chromewebstore.google.com/detail/dhnnjmnnnmdpnlkkmlhppligleiefdhk/support"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="inherit"
                    sx={{
                      textTransform: 'none',
                      fontSize: 'inherit'
                    }}
                  >
                    Support
                  </Button>
                  <Button 
                    component="a"
                    href="mailto:contact@aaiimm.com"
                    color="inherit" 
                    sx={{ 
                      textTransform: 'none',
                      fontSize: 'inherit'
                    }}
                  >
                    Contact
                  </Button>
                </Typography>
              </Box>
            </Container>
          </ThemeProvider>
        } />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </Router>
  );
}

export default App;
